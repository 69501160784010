import React, { useCallback, useEffect, useState } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';

function Notes() {
    const [quill, setQuill] = useState(null);

    const initQuill = useCallback((node) => {
        if (node == null) return;

        const editor = new Quill(node, {
            theme: 'snow',
            modules: {
                toolbar: [
                    [{ header: [1, 2, false] }],
                    ['bold', 'italic', 'underline', 'strike'],
                    ['blockquote', 'code-block'],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    [{ indent: '-1' }, { indent: '+1' }],
                    [{ size: ['small', false, 'large', 'huge'] }],
                    [{ color: [] }, { background: [] }],
                    ['clean'],
                    ['link', 'image', 'video'],
                ],
            },
            placeholder: 'Start typing...',
        });

        setQuill(editor);
    }, []);

    // Cleanup when the component unmounts
    useEffect(() => {
        return () => {
            if (quill != null) {
                // You may want to save the data when the component unmounts

                quill.deleteText(0, quill.getLength());
                setQuill(null);
            }
        };
    }, [quill]);

    const saveContent = () => {
        if (quill) {
            const content = quill.getContents();
            // Implement saving functionality. For example:
            // localStorage.setItem('notes-content', JSON.stringify(content));
            console.log('Saving content', content);
        }
    };
    
    const printContent = () => {
        if (quill) {
            const printWindow = window.open('', '_blank');
            printWindow.document.write('<html><head><title>Print</title></head><body>');
            printWindow.document.write(quill.root.innerHTML);
            printWindow.document.write('</body></html>');
            printWindow.document.close();
            printWindow.print();
        }
    };

    return (
        <div className="notes-container">
        
            <div
                className="quill-editor"
                ref={initQuill}
                style={{ height: '90%', padding: '10px', border: '1px solid gray', overflow: 'auto' }}
                onMouseDown={event => event.stopPropagation()}
                onMouseUp={event => event.stopPropagation()}
                onClick={event => event.stopPropagation()}
            />
            <div className="toolbar">
                <button className="green-button" onClick={saveContent}>Save</button>
                <button className="green-button" onClick={printContent}>Print</button>
            </div>
        </div>
    );
}

export default Notes;
