import React, { useState, useEffect } from 'react';
import config from '../config';

const PromptsList = ({ onSelectPrompt }) => {
    const [prompts, setPrompts] = useState([]);
    const [filteredPrompts, setFilteredPrompts] = useState([]);
    const [filter, setFilter] = useState('enabled');
    const [searchTerm, setSearchTerm] = useState('');
    const [sortBy, setSortBy] = useState('name');
    const [sortOrder, setSortOrder] = useState('asc');
    const [currentPage, setCurrentPage] = useState(1);
    const [promptsPerPage] = useState(10);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        fetchPrompts();
    }, []);

    useEffect(() => {
        filterAndSortPrompts();
    }, [prompts, filter, searchTerm, sortBy, sortOrder]);

    const fetchPrompts = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${config.apiBaseUrl}/api/prompts`, {
                credentials: 'include'
            });
            if (!response.ok) throw new Error('Failed to fetch prompts');
            const data = await response.json();
            setPrompts(data);
            setError('');
        } catch (error) {
            setError('Error fetching prompts: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    const filterAndSortPrompts = () => {
        let result = [...prompts];
        
        if (filter !== 'all') {
            result = result.filter(prompt => prompt.status === filter);
        }
        
        if (searchTerm) {
            result = result.filter(prompt => 
                prompt.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                prompt.description.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }
        
        result.sort((a, b) => {
            if (a[sortBy] < b[sortBy]) return sortOrder === 'asc' ? -1 : 1;
            if (a[sortBy] > b[sortBy]) return sortOrder === 'asc' ? 1 : -1;
            return 0;
        });
        
        setFilteredPrompts(result);
    };

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
        setCurrentPage(1);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1);
    };

    const handleSortChange = (newSortBy) => {
        if (sortBy === newSortBy) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(newSortBy);
            setSortOrder('asc');
        }
        setCurrentPage(1);
    };

    const handleDragStart = (e, prompt) => {
        e.dataTransfer.setData('application/json', JSON.stringify({
            type: 'prompt',
            id: prompt.id,
            name: prompt.name,
            prompt: prompt.prompt
        }));
    };

    const handleRowClick = (e, promptId) => {
        if (!e.target.closest('.delete-button')) {
            onSelectPrompt(promptId);
        }
    };

    const handleDeleteClick = async (e, promptId) => {
        e.preventDefault();
        e.stopPropagation();

        if (window.confirm('Are you sure you want to delete this prompt?')) {
            try {
                const response = await fetch(`${config.apiBaseUrl}/api/prompts/${promptId}`, {
                    method: 'DELETE',
                    credentials: 'include'
                });
                if (!response.ok) throw new Error('Failed to delete prompt');
                setPrompts(prompts.filter(prompt => prompt.id !== promptId));
            } catch (error) {
                setError('Error deleting prompt: ' + error.message);
            }
        }
    };

    const indexOfLastPrompt = currentPage * promptsPerPage;
    const indexOfFirstPrompt = indexOfLastPrompt - promptsPerPage;
    const currentPrompts = filteredPrompts.slice(indexOfFirstPrompt, indexOfLastPrompt);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    if (loading) {
        return <div className="prompts-list">Loading prompts...</div>;
    }

    if (error) {
        return <div className="prompts-list error">{error}</div>;
    }

    return (
        <div className="prompts-list">
            <h2>Prompts List</h2>
            <div className="prompts-controls">
                <select value={filter} onChange={handleFilterChange} className="filter-select">
                    <option value="all">All Prompts</option>
                    <option value="enabled">Enabled</option>
                    <option value="disabled">Disabled</option>
                    <option value="hidden">Hidden</option>
                </select>
                <input
                    type="text"
                    placeholder="Search prompts..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className="search-input"
                />
            </div>
            <table className="prompts-table">
                <thead>
                    <tr>
                        <th onClick={() => handleSortChange('name')}>
                            Name {sortBy === 'name' && (sortOrder === 'asc' ? '▲' : '▼')}
                        </th>
                        <th onClick={() => handleSortChange('description')}>
                            Description {sortBy === 'description' && (sortOrder === 'asc' ? '▲' : '▼')}
                        </th>
                        <th onClick={() => handleSortChange('status')}>
                            Status {sortBy === 'status' && (sortOrder === 'asc' ? '▲' : '▼')}
                        </th>
                        <th onClick={() => handleSortChange('timestamp')}>
                            Last Modified {sortBy === 'timestamp' && (sortOrder === 'asc' ? '▲' : '▼')}
                        </th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {currentPrompts.map(prompt => (
                        <tr 
                            key={prompt.id} 
                            onClick={(e) => handleRowClick(e, prompt.id)}
                            className="prompt-item"
                            draggable
                            onDragStart={(e) => handleDragStart(e, prompt)}
                        >
                            <td>{prompt.name}</td>
                            <td>{prompt.description}</td>
                            <td>{prompt.status}</td>
                            <td>{new Date(prompt.timestamp).toLocaleString()}</td>
                            <td>
                                <button 
                                    className="delete-button"
                                    onClick={(e) => handleDeleteClick(e, prompt.id)}
                                >
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="pagination">
                {Array.from({ length: Math.ceil(filteredPrompts.length / promptsPerPage) }, (_, i) => (
                    <button key={i} onClick={() => paginate(i + 1)} className={currentPage === i + 1 ? 'active' : ''}>
                        {i + 1}
                    </button>
                ))}
            </div>
            {filteredPrompts.length === 0 && <p>No prompts found.</p>}
        </div>
    );
};

export default PromptsList;