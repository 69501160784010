import React, { useState, useEffect } from 'react';

const Tooltip = ({ children, content }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    let showTimer;
    let hideTimer;

    if (shouldRender) {
      showTimer = setTimeout(() => {
        setIsVisible(true);
      }, 1000); // 2 seconds delay
    } else {
      hideTimer = setTimeout(() => {
        setIsVisible(false);
      }, 200); // Small delay for smoother hiding
    }

    return () => {
      clearTimeout(showTimer);
      clearTimeout(hideTimer);
    };
  }, [shouldRender]);

  return (
    <div className="relative inline-block">
      <div
        onMouseEnter={() => setShouldRender(true)}
        onMouseLeave={() => setShouldRender(false)}
      >
        {children}
      </div>
      {isVisible && (
        <div className="absolute z-10 bottom-full left-1/2 transform -translate-x-1/2 mb-3">
          <div className="relative bg-yellow-300 text-black text-lg font-medium px-4 py-3 rounded-lg border-2 border-black shadow-lg w-max max-w-xs">
            {content}
            <div className="absolute w-4 h-4 bg-yellow-300 border-b-2 border-r-2 border-black transform rotate-45 -bottom-2 left-1/2 -ml-2"></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Tooltip;