import React, { useState, useEffect, useRef } from 'react';
import config from '../config';
import backgroundImage from '../public/background.jpg';
import Tooltip from './Tooltip';  // Import the Tooltip component

const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
const recognition = new SpeechRecognition();
recognition.continuous = false;
recognition.interimResults = false;
recognition.lang = 'sv-SE'; // Set the language to Swedish

const groupChatsByDate = (chats) => {
  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  const lastWeek = new Date(today);
  lastWeek.setDate(lastWeek.getDate() - 7);
  const lastMonth = new Date(today);
  lastMonth.setDate(lastMonth.getDate() - 30);

  return chats.reduce((acc, chat) => {
    const chatDate = new Date(chat.timestamp);
    if (chatDate >= today) {
      acc.today.push(chat);
    } else if (chatDate >= yesterday) {
      acc.yesterday.push(chat);
    } else if (chatDate >= lastWeek) {
      acc.lastWeek.push(chat);
    } else if (chatDate >= lastMonth) {
      acc.lastMonth.push(chat);
    }
    return acc;
  }, { today: [], yesterday: [], lastWeek: [], lastMonth: [] });
};

const NonAdminChat = () => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [chatUuid, setChatUuid] = useState('');
  const [defaultModel, setDefaultModel] = useState('');
  const [readReply, setReadReply] = useState(false);
  const [isPlayingAudio, setIsPlayingAudio] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [isFeedbackLoading, setIsFeedbackLoading] = useState(false);
  const [warningMessage, setWarningMessage] = useState('');
  const [infoMessage, setInfoMessage] = useState('');
  const [profiles, setProfiles] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [showSummary, setShowSummary] = useState(false);
  const [isSummaryLoading, setIsSummaryLoading] = useState(false);
  const [summaryResult, setSummaryResult] = useState({ feedbacktogrownup: '' });
  const [showQuestionnaire, setShowQuestionnaire] = useState(false);
  const [userChats, setUserChats] = useState([]);
  const [currentSplash, setCurrentSplash] = useState('info');
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);
  
  const messagesEndRef = useRef(null);
  const audioRef = useRef(new Audio());
  const inputRef = useRef(null);

  useEffect(() => {
    initializeChat();
    fetchUserChats();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    inputRef.current?.focus();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const initializeChat = async () => {
    await fetchDefaultModelAndMessages();
    await fetchProfiles();
  };

  const fetchUserChats = async () => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/api/chats`, {
        method: 'GET',
        credentials: 'include',
      });
      if (response.ok) {
        const chats = await response.json();
        setUserChats(chats);
      } else {
        console.error('Misslyckades med att hämta användarens chattar');
      }
    } catch (error) {
      console.error('Fel vid hämtning av användarens chattar:', error);
    }
  };

  const handleChatSelect = async (selectedChatUuid) => {
    setChatUuid(selectedChatUuid);
    const selectedChat = userChats.find(chat => chat.chat_uuid === selectedChatUuid);
    if (selectedChat && selectedChat.profile) {
      setSelectedProfile(selectedChat.profile);
    }
    await fetchMessages(selectedChatUuid);
  };

  const fetchDefaultModelAndMessages = async () => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/api/preferences`);
      const data = await response.json();
      const defaultModelPreference = data.find(pref => pref.key === 'default_model');
      const warningPreference = data.find(pref => pref.key === 'chat_warning');
      const infoPreference = data.find(pref => pref.key === 'chat_info');
      if (defaultModelPreference) {
        setDefaultModel(defaultModelPreference.value);
      }
      if (warningPreference) {
        setWarningMessage(warningPreference.value);
      }
      if (infoPreference) {
        setInfoMessage(infoPreference.value);
      }
    } catch (error) {
      console.error('Fel vid hämtning av inställningar:', error);
    }
  };

  const fetchProfiles = async () => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/api/profiles`);
      const data = await response.json();
      const publishedProfiles = data.filter(profile => profile.published);
      setProfiles(publishedProfiles);
    } catch (error) {
      console.error('Fel vid hämtning av profiler:', error);
    }
  };

  const fetchMessages = async (chatUuid) => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/api/load_chat?chat_uuid=${chatUuid}`, {
        method: 'GET',
        credentials: 'include',
      });
      if (response.ok) {
        const chatMessages = await response.json();
        // Filter out any null messages and sort them by order
        const validMessages = chatMessages
          .filter(msg => msg !== null)
          .sort((a, b) => a.order - b.order);
        setMessages(validMessages);
      } else {
        throw new Error('Failed to fetch messages');
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    } 
  };

  const handleSendMessage = async (messageText, sender = 'user', type = null, uuid = chatUuid) => {
    if (messageText.trim()) {
      const message = { content: messageText, role: sender, model: defaultModel, type: type };
      setMessages(prevMessages => [...prevMessages, message]);
      setInputMessage('');

      const messageData = {
        chat_uuid: uuid,
        message: messageText,
        model: defaultModel,
        order: messages.length + 1,
        type: type
      };

      try {
        const response = await fetch(`${config.apiBaseUrl}/api/chat`, {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(messageData)
        });

        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let botMessage = '';

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
          const chunk = decoder.decode(value, { stream: true });
          botMessage += chunk;
          setMessages(prevMessages => {
            const lastMessage = prevMessages[prevMessages.length - 1];
            if (lastMessage && lastMessage.role === 'assistant') {
              return [...prevMessages.slice(0, -1), { ...lastMessage, content: botMessage }];
            } else {
              return [...prevMessages, { content: botMessage, role: 'assistant', model: defaultModel }];
            }
          });
        }

        if (readReply) {
          await speakText(botMessage);
        }
        
        if (messages.filter(m => m.role === 'user').length % 5 === 0) {
          updateChatDescription(uuid);
        }
      } catch (error) {
        console.error('Fel i chattkommunikation:', error);
      }
    }
  };

  const updateChatDescription = async (chatUuid) => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/api/update_chat_description`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ chat_uuid: chatUuid })
      });
      if (response.ok) {
        const data = await response.json();
        setUserChats(prevChats => 
          prevChats.map(chat => 
            chat.chat_uuid === chatUuid 
            ? { ...chat, description: data.description } 
            : chat
          )
        );
      }
    } catch (error) {
      console.error('Error updating chat description:', error);
    }
  };
  
  const speakText = async (text) => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/api/text-to-speech`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text }),
      });

      if (!response.ok) {
        throw new Error('Misslyckades med att generera tal');
      }

      const blob = await response.blob();
      const audioUrl = URL.createObjectURL(blob);

      audioRef.current.src = audioUrl;
      audioRef.current.onended = () => setIsPlayingAudio(false);
      setIsPlayingAudio(true);
      await audioRef.current.play();
    } catch (error) {
      console.error('Fel vid generering eller uppspelning av tal:', error);
    }
  };

  const handleSpeechInput = () => {
    if (!isListening) {
      setIsListening(true);
      recognition.start();
    } else {
      setIsListening(false);
      recognition.stop();
    }
  };

  recognition.onresult = (event) => {
    const transcript = event.results[0][0].transcript;
    setInputMessage(transcript);
    setIsListening(false);
  };

  recognition.onerror = (event) => {
    console.error('Fel vid taligenkänning:', event.error);
    setIsListening(false);
  };

  const handleFeedbackClick = async () => {
    setIsFeedbackLoading(true);

    if (messages.length === 0) {
      console.error('Inga meddelanden att ge feedback på');
      setIsFeedbackLoading(false);
      return;
    }

    const messageData = {
      chat_uuid: chatUuid,
      message: messages[messages.length - 1].content,
      model: defaultModel,
      order: messages.length
    };

    try {
      const helperResult = await callHelper(messageData);
      if (helperResult && helperResult.feedbacktochild && helperResult.feedbacktogrownup) {
        const formattedHelperMessage = `${helperResult.feedbacktochild}\n\n${helperResult.feedbacktogrownup}`;

        setMessages(prevMessages => {
          const newMessages = [...prevMessages];
          const lastMessageIndex = newMessages.length - 1;
          newMessages[lastMessageIndex] = {
            ...newMessages[lastMessageIndex],
            feedback: formattedHelperMessage
          };
          return newMessages;
        });

        await fetch(`${config.apiBaseUrl}/api/update_feedback`, {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ 
            chat_uuid: chatUuid, 
            order: messages.length - 2, 
            feedback: formattedHelperMessage 
          })
        });
      }
    } catch (error) {
      console.error('Fel vid hämtning av feedback:', error);
    } finally {
      setIsFeedbackLoading(false);
    }
  };

  const callHelper = async (messageData) => {
    try {
      const helperResponse = await fetch(`${config.apiBaseUrl}/api/chat/helper`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(messageData)
      });
      return await helperResponse.json();
    } catch (error) {
      console.error('Fel vid anrop av hjälpare:', error);
      return null;
    }
  };

  const handleNoteClick = (index) => {
    const currentNote = messages[index].note || '';
    const note = prompt('Ange en anteckning för detta meddelande:', currentNote);
    if (note !== null) {
      setMessages(prevMessages => {
        const newMessages = [...prevMessages];
        newMessages[index] = { ...newMessages[index], note };
        fetch(`${config.apiBaseUrl}/api/update_note`, {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ chat_uuid: chatUuid, order: index, note })
        });
        return newMessages;
      });
    }
  };

  const handleInfoConfirm = () => {
    setCurrentSplash('warning');
  };

  const handleWarningConfirm = () => {
    setCurrentSplash('profiles');
  };

  const handleProfileSelect = async (profile) => {
    setSelectedProfile(profile);
    setCurrentSplash(null);

    try {
      const response = await fetch(`${config.apiBaseUrl}/api/initialize_chat`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ profile_id: profile.id })
      });

      if (!response.ok) {
        throw new Error('Failed to initialize chat');
      }

      const data = await response.json();
      setChatUuid(data.chat_uuid);

      await fetchMessages(data.chat_uuid);
      await fetchUserChats();  // Refresh the chat list to include the new chat

      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    } catch (error) {
      console.error('Error initializing chat:', error);
    }
  };

  const handleEvaluationClick = async () => {
    setIsSummaryLoading(true);
    setShowSummary(true);

    try {
      const response = await fetch(`${config.apiBaseUrl}/api/chat/evaluation`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ chat_uuid: chatUuid })
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const result = await response.text();
      console.log('Raw API response:', result);
      
      setSummaryResult({
        feedbacktogrownup: result.trim()
      });
    } catch (error) {
      console.error('Error fetching summary:', error);
      setSummaryResult({
        feedbacktogrownup: `Ett fel uppstod vid hämtning av sammanfattning: ${error.message}`
      });
    } finally {
      setIsSummaryLoading(false);
    }
  };
  
  const handleCommentSubmit = async (comment) => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/api/chats/${chatUuid}`, {
        method: 'PUT',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ comments: comment })
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const result = await response.json();
      console.log('Comment saved successfully:', result);
      
      resetChat();
    } catch (error) {
      console.error('Fel vid inlämning av kommentar:', error);
    }
  };
  
  const resetChat = () => {
    setMessages([]);
    setChatUuid('');
    setSelectedProfile(null);
    setShowSummary(false);
    setCurrentSplash('profiles');
  };
  
  const handleCloseChatAndShowProfiles = () => {
    setShowCloseConfirmation(true);
  };
  
  const confirmCloseChatAndShowProfiles = () => {
    resetChat();
    setShowCloseConfirmation(false);
  };
  
  const renderMessages = () => {
    let skipNext = false;
    return messages.map((message, index) => {
      if (skipNext) {
        skipNext = false;
        return null;
      }
      
      if (message.type === 'special') {
        skipNext = true;
        return null;
      }
      
      return (
        <div key={index} className="message-container">
        <div className={`message flex items-center my-2 p-2 rounded w-4/5 ${
          message.role === "user" ? 'justify-end ml-auto bg-blue-200' : 
          message.role === "assistant" ? 'justify-start mr-auto bg-gray-200' :
          'justify-start mr-auto bg-gray-200'
        }`}>
        {message.role === "assistant" && <i className="fas fa-robot mr-2"></i>}
        {message.role === "system" && <i className="fas fa-info-circle mr-2"></i>}
        <span className={`message-text flex-1 ${message.role === "user" ? 'text-right' : 'text-left'}`}>
        {message.content}
        </span>
        {message.role !== "system" && (
          <i className="fas fa-sticky-note ml-2 cursor-pointer" onClick={() => handleNoteClick(index)}></i>
        )}
        {message.role === "user" && <i className="fas fa-user ml-2"></i>}
        </div>
        {message.note && (
          <div className={`note flex items-center my-2 p-2 rounded w-4/5 ${message.role === "user" ? 'justify-end bg-yellow-200 ml-auto' : 'justify-start bg-yellow-200 mr-auto'}`}>
          {message.note}
          </div>
        )}
        {message.feedback && (
          <div className="feedback flex items-start my-2 p-2 rounded w-4/5 bg-green-100 mr-auto">
          <div className="text-left">
          <div dangerouslySetInnerHTML={{ __html: message.feedback }} />
          </div>
          </div>
        )}
        </div>
      );
    }).filter(Boolean);
  };
  
  const renderChatHistory = () => {
    const groupedChats = groupChatsByDate(userChats);
    
    const renderChatGroup = (chats, title) => (
      chats.length > 0 && (
        <div key={title} className="mb-4">
        <h3 className="font-semibold text-lg mb-2 bg-blue-100 p-2 rounded">{title}</h3>
        <ul className="space-y-2">
        {chats.map((chat) => (
          <li 
          key={chat.chat_uuid} 
          className="cursor-pointer hover:bg-gray-200 p-2 rounded text-left"
          onClick={() => handleChatSelect(chat.chat_uuid)}
          >
          <p className="font-bold">{chat.profile ? chat.profile.public_name : 'Ingen profil'}</p>
          <p className="text-sm text-gray-600">[{new Date(chat.timestamp).toLocaleTimeString()}]</p>
          
          <p className="text-sm text-gray-600">{chat.description || 'Namnlös chatt'}</p>
          </li>
        ))}
        </ul>
        </div>
      )
    );
    
    return (
      <>
      {renderChatGroup(groupedChats.today, 'Idag')}
      {renderChatGroup(groupedChats.yesterday, 'Igår')}
      {renderChatGroup(groupedChats.lastWeek, 'Senaste 7 dagarna')}
      {renderChatGroup(groupedChats.lastMonth, 'Senaste 30 dagarna')}
      </>
    );
  };
  
  const SplashOverlayBackground = ({ children }) => (
    <div 
    className="fixed inset-0 z-50 flex items-center justify-center"
    style={{
      backgroundImage: `url(${backgroundImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      backgroundAttachment: 'fixed'
    }}
    >
    {children}
    </div>
  );
  
  const InfoSplashOverlay = ({ message, onConfirm }) => (
    <SplashOverlayBackground>
    <div className="bg-white bg-opacity-90 p-8 rounded-lg max-w-4xl w-full max-h-[80vh] overflow-y-auto">
    <div className="flex items-center justify-center mb-4">
    <svg className="w-12 h-12 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
    </svg>
    </div>
    <div 
    className="text-left mb-6 info-content"
    dangerouslySetInnerHTML={{ __html: message }}
    />
    <button 
    onClick={onConfirm}
    className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
    >
    Jag förstår
    </button>
    </div>
    </SplashOverlayBackground>
  );
  
  const WarningOverlay = ({ message, onConfirm }) => (
    <SplashOverlayBackground>
    <div className="bg-white bg-opacity-90 p-8 rounded-lg max-w-4xl w-full max-h-[80vh] overflow-y-auto">
    <div className="flex items-center justify-center mb-4">
    <svg className="w-12 h-12 text-yellow-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
    </svg>
    </div>
    <div 
    className="text-left mb-6 warning-content"
    dangerouslySetInnerHTML={{ __html: message }}
    />
    <button 
    onClick={onConfirm}
    className="w-full bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded"
    >
    Jag förstår
    </button>
    </div>
    </SplashOverlayBackground>
  );
  
  const ProfilesOverlay = ({ profiles, onSelect, onEvaluationClick }) => (
    <SplashOverlayBackground>
    <div className="bg-white bg-opacity-90 p-8 rounded-lg max-w-4xl w-full h-[80vh] flex flex-col">
    <h2 className="text-2xl font-bold mb-4">Välj en profil</h2>
    <p>Skillnaden mellan variant 1 och variant 2 är att de har olika bakgrundshistorier.</p>
    <div className="flex-grow overflow-y-auto mb-4">
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
    {profiles.map((profile) => (
      <div 
      key={profile.id} 
      className="bg-gray-100 p-4 rounded-lg cursor-pointer hover:bg-gray-200 transition"
      onClick={() => onSelect(profile)}
      >
      <img
      src={`${config.apiBaseUrl}/api/profiles/${profile.id}/image`}
      alt={profile.public_name}
      className="w-full h-48 object-cover mb-2 rounded"
      onError={(e) => { e.target.onerror = null; e.target.src = '/default-profile.png'; }}
      />
      <h3 className="font-semibold">{profile.public_name}</h3>
      <p className="text-sm text-gray-600">{profile.description}</p>
      </div>
    ))}
    </div>
    </div>
    <Tooltip content="Öppnar ett utvärderingsformulär för det här verktyget">
    <button 
    onClick={() => setShowQuestionnaire(true)}
    className="w-full bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded"
    >
    Utvärdera verktyget
    </button>
    </Tooltip>
    </div>
    </SplashOverlayBackground>
  );
  
  const SummaryOverlay = ({ summary, isLoading, onClose, initialComment, onCommentSubmit }) => {
    const [localComment, setLocalComment] = useState(initialComment);
    const textareaRef = useRef(null);
    
    useEffect(() => {
      if (textareaRef.current) {
        textareaRef.current.focus();
      }
    }, []);
    
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg max-w-4xl w-full max-h-[80vh] overflow-y-auto">
      <h2 className="text-2xl font-bold mb-4">Sammanfattning</h2>
      {isLoading ? (
        <div className="flex justify-center items-center">
        <svg className="animate-spin h-10 w-10 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        <span className="ml-2">Sammanfattning av samtal skapas...</span>
        </div>
      ) : (
        <>
        <div className="mb-4 text-left">
        <div dangerouslySetInnerHTML={{ __html: summary.feedbacktogrownup }} />
        </div>
        <div className="mt-4">
        <textarea
        ref={textareaRef}
        value={localComment}
        onChange={(e) => setLocalComment(e.target.value)}
        placeholder="Lägg till en kommentar..."
        className="w-full p-2 border rounded"
        rows="4"
        />
        <div className="flex justify-end mt-2">
        <Tooltip content="Stänger sammanfattningen utan att spara">
  
          <button 
          onClick={onClose}
          className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mr-2"
          >
          Stäng
          </button>
        </Tooltip>
        <Tooltip content="Stänger och sparar kommentaren">
          <button 
          onClick={() => onCommentSubmit(localComment)}
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
          >
          Skicka kommentar
          </button>
        </Tooltip>
        </div>
        </div>
        </>
      )}
      </div>
      </div>
    );
  };
  
  const QuestionnaireOverlay = ({ onClose }) => (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
    <div className="bg-white p-8 rounded-lg w-full max-w-4xl max-h-[90vh] overflow-y-auto">
    <div className="flex justify-end mb-4">
    <button 
    onClick={onClose}
    className="text-gray-500 hover:text-gray-700"
    >
    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
    </svg>
    </button>
    </div>
    <iframe 
    src="https://docs.google.com/forms/d/e/1FAIpQLSfPf3gMLx6yICHzpgOhPoPsqlWDJEaMCzzF2Bp4-Qq4FHJgPg/viewform?embedded=true" 
    width="100%" 
    height="4321" 
    frameBorder="0" 
    marginHeight="0" 
    marginWidth="0"
    title="Questionnaire"
    >
    Läser in …
    </iframe>
    </div>
    </div>
  );
  
  const CloseConfirmationDialog = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
    <div className="bg-white p-6 rounded-lg max-w-sm w-full">
    <h2 className="text-xl font-bold mb-4">Är du säker?</h2>
    <p className="mb-4">Vill du verkligen stänga chatten och visa profilerna igen?</p>
    <div className="flex justify-end space-x-2">
    <button 
    onClick={() => setShowCloseConfirmation(false)}
    className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded"
    >
    Avbryt
    </button>
    <button 
    onClick={confirmCloseChatAndShowProfiles}
    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
    >
    Ja, stäng chatten
    </button>
    </div>
    </div>
    </div>
  );
  
  return (
    <div className="non-admin-chat-container max-w-7xl mx-auto p-4 flex h-screen">
    {currentSplash === 'info' && infoMessage && (
      <InfoSplashOverlay 
      message={infoMessage} 
      onConfirm={handleInfoConfirm} 
      />
    )}
    {currentSplash === 'warning' && warningMessage && (
      <WarningOverlay 
      message={warningMessage} 
      onConfirm={handleWarningConfirm} 
      />
    )}
    {currentSplash === 'profiles' && (
      <ProfilesOverlay 
      profiles={profiles} 
      onSelect={handleProfileSelect} 
      />
    )}
    {showSummary && (
      <SummaryOverlay 
      summary={summaryResult}
      isLoading={isSummaryLoading}
      onClose={resetChat}
      initialComment=""
      onCommentSubmit={handleCommentSubmit}
      />
    )}
    {showQuestionnaire && (
      <QuestionnaireOverlay onClose={() => setShowQuestionnaire(false)} />
    )}
    {showCloseConfirmation && <CloseConfirmationDialog />}
    
    <div className="w-1/5 bg-gray-100 p-4 rounded-lg flex flex-col mr-4">
    <h2 className="text-lg font-semibold mb-4">Chatthistorik</h2>
    <div className="overflow-y-auto flex-grow">
    {userChats.length > 0 ? (
      renderChatHistory()
    ) : (
      <p className="text-gray-500">Ingen chatthistorik tillgänglig.</p>
    )}
    </div>
    </div>
    
    <div className="w-3/5 bg-white p-4 rounded-lg shadow flex flex-col">
    <div className="chat-messages flex-grow overflow-y-auto mb-4">
    {renderMessages()}
    <div ref={messagesEndRef} />
    </div>
    <div className="chat-input flex items-center">
    <input
    ref={inputRef}
    type="text"
    value={inputMessage}
    onChange={(e) => setInputMessage(e.target.value)}
    onKeyPress={(e) => e.key === 'Enter' && handleSendMessage(inputMessage)}
    placeholder="Skriv ett meddelande..."
    className="flex-1 border rounded p-2 mr-2"
    />
    <button 
    onClick={() => handleSendMessage(inputMessage)}
    className="send-button bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
    >
    Skicka
    </button>
    <button
    onClick={handleSpeechInput}
    className={`speech-input-button ${isListening ? 'bg-red-500 hover:bg-red-700' : 'bg-blue-500 hover:bg-blue-700'} text-white font-bold py-2 px-4 rounded mr-2`}
    >
    {isListening ? 'Stoppa' : 'Tala'}
    </button>
    
    <Tooltip content="Coachen ger dig feedback på hur samtalet går samt tips på hur du kan gå vidare">
      <button 
      onClick={handleFeedbackClick}
      disabled={isFeedbackLoading || messages.length === 0}
      className={`feedback-button ${
        isFeedbackLoading || messages.length === 0
        ? 'bg-gray-500 cursor-not-allowed' 
        : 'bg-green-500 hover:bg-green-700'
        } text-white font-bold py-2 px-4 rounded flex items-center justify-center`}
      >
      {isFeedbackLoading ? (
        <>
        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        Laddar...
        </>
      ) : (
        'Coach'
      )}
      </button>
    </Tooltip>
    </div>
    <div className="mt-2 flex items-center">
    <label className="read-reply-checkbox flex items-center">
    <input
    type="checkbox"
    checked={readReply}
    onChange={(e) => setReadReply(e.target.checked)}
    className="mr-2"
    />
    Läs upp svar
    </label>
    </div>
    </div>
    
    <div className="w-1/5 bg-gray-100 p-4 rounded-lg flex flex-col ml-4">
    <h2 className="text-lg font-semibold mb-4">Profil</h2>
    {selectedProfile ? (
      <div>
      <img
      src={`${config.apiBaseUrl}/api/profiles/${selectedProfile.id}/image`}
      alt={selectedProfile.public_name}
      className="w-full h-48 object-cover mb-2 rounded"
      onError={(e) => { e.target.onerror = null; e.target.src = '/default-profile.png'; }}
      />
      <h3 className="font-semibold">{selectedProfile.public_name}</h3>
      <p className="text-sm text-gray-600">{selectedProfile.description}</p>
      </div>
    ) : (
      <p className="text-gray-500">Ingen profil vald.</p>
    )}
    <Tooltip content="Avslutar samtalet och skapar en återkoppling och analys av hur samtalet har gått.">

      <button 
      onClick={handleEvaluationClick}
      className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
      >
      Avsluta samtal och få återkoppling
      </button>
    </Tooltip>
    <Tooltip content="Stänger nuvarande chat och låter dig välja en ny profil att chatta med">
      <button 
      onClick={handleCloseChatAndShowProfiles}
      className="w-full bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-2"
      >
      Stäng chat och visa profiler
      </button>
    </Tooltip>
    </div>
    {isPlayingAudio && (
      <div className="fixed bottom-4 right-4 bg-blue-100 border-blue-500 border-2 p-4 rounded shadow-lg">
      Spelar upp ljud...
      </div>
    )}
    </div>
  );
};

export default NonAdminChat;