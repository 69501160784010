import React, { useState } from 'react';
import config from '../config';

function CreateUserForm({ onUserCreated }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError('');
    
    try {
      const response = await fetch(`${config.apiBaseUrl}/api/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
      
      const data = await response.json();
      if (response.ok) {
        onUserCreated(data);
        setEmail('');
        setPassword('');
      } else {
        setError(data.error || 'Failed to create user');
      }
    } catch (error) {
      setError('Network error: Could not contact the server');
    }
    
    setLoading(false);
  };
  
  return (<h2></h2>);
  return (
    <div className="create-user-form">
      <h2>Create User</h2>
      <form onSubmit={handleSubmit}>
        <label htmlFor="email">Email:</label>
        <input type="email" id="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Enter your email" required />
        <label htmlFor="password">Password:</label>
        <input type="password" id="password" value={password} onChange={e => setPassword(e.target.value)} placeholder="Enter your password" required />
        <button type="submit" disabled={loading}>{loading ? 'Creating...' : 'Create User'}</button>
      </form>
    {error && <p className="error">{error}</p>}
    </div>
  );
}

export default CreateUserForm;

  