import React, { useState, useEffect } from 'react';
import config from '../config';

function Preferences() {
    const [preferences, setPreferences] = useState([]);
    const [newKey, setNewKey] = useState('');
    const [newValue, setNewValue] = useState('');
    const [editId, setEditId] = useState(null);
    const [editKey, setEditKey] = useState('');
    const [editValue, setEditValue] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchPreferences();
    }, []);

    const fetchPreferences = async () => {
        try {
            setLoading(true);
            const response = await fetch(`${config.apiBaseUrl}/api/preferences`, {
                credentials: 'include'
            });
            if (!response.ok) throw new Error('Failed to fetch preferences');
            const data = await response.json();
            setPreferences(data);
            setError('');
        } catch (error) {
            setError('Error fetching preferences: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleAddPreference = async () => {
        try {
            if (!newKey.trim() || !newValue.trim()) {
                setError('Both key and value are required');
                return;
            }
            const response = await fetch(`${config.apiBaseUrl}/api/preferences`, {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ key: newKey, value: newValue })
            });
            if (!response.ok) throw new Error('Failed to add preference');
            await fetchPreferences();
            setNewKey('');
            setNewValue('');
            setError('');
        } catch (error) {
            setError('Error adding preference: ' + error.message);
        }
    };

    const handleUpdatePreference = async (id) => {
        try {
            if (!editKey.trim() || !editValue.trim()) {
                setError('Both key and value are required');
                return;
            }
            const response = await fetch(`${config.apiBaseUrl}/api/preferences/${id}`, {
                method: 'PUT',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ key: editKey, value: editValue })
            });
            if (!response.ok) throw new Error('Failed to update preference');
            await fetchPreferences();
            setEditId(null);
            setEditKey('');
            setEditValue('');
            setError('');
        } catch (error) {
            setError('Error updating preference: ' + error.message);
        }
    };

    const handleDeletePreference = async (id) => {
        try {
            const response = await fetch(`${config.apiBaseUrl}/api/preferences/${id}`, {
                method: 'DELETE',
                credentials: 'include'
            });
            if (!response.ok) throw new Error('Failed to delete preference');
            await fetchPreferences();
            setError('');
        } catch (error) {
            setError('Error deleting preference: ' + error.message);
        }
    };

    const handleEditClick = (preference) => {
        setEditId(preference.id);
        setEditKey(preference.key);
        setEditValue(preference.value);
    };

    if (loading) {
        return <div className="preferences-container">Loading preferences...</div>;
    }

    return (
        <div className="preferences-container">
            <h2>Preferences</h2>
            {error && <p className="error">{error}</p>}
            <div className="add-preference">
                <input
                    type="text"
                    placeholder="Key"
                    value={newKey}
                    onChange={(e) => setNewKey(e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Value"
                    value={newValue}
                    onChange={(e) => setNewValue(e.target.value)}
                />
                <button onClick={handleAddPreference}>Add</button>
            </div>
            <ul className="preferences-list">
                {preferences.map((preference) => (
                    <li key={preference.id} className="preference-item">
                        {editId === preference.id ? (
                            <>
                                <input
                                    type="text"
                                    className="edit-input"
                                    value={editKey}
                                    onChange={(e) => setEditKey(e.target.value)}
                                />
                                <input
                                    type="text"
                                    className="edit-input"
                                    value={editValue}
                                    onChange={(e) => setEditValue(e.target.value)}
                                />
                                <div className="edit-buttons">
                                    <button onClick={() => handleUpdatePreference(preference.id)}>Save</button>
                                    <button onClick={() => setEditId(null)}>Cancel</button>
                                </div>
                            </>
                        ) : (
                            <>
                                <span className="preference-content">
                                    <strong>{preference.key}:</strong> {preference.value}
                                </span>
                                <div className="preference-actions">
                                    <button onClick={() => handleEditClick(preference)}>Edit</button>
                                    <button onClick={() => handleDeletePreference(preference.id)}>Delete</button>
                                </div>
                            </>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default Preferences;