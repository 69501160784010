import React, { useState, useEffect } from 'react';
import { Rnd } from 'react-rnd';
import './App.css';
import CreateUserForm from './components/CreateUserForm';
import LoginForm from './components/LoginForm';
import Chat from './components/Chat';
import NonAdminChat from './components/NonAdminChat';
import Notes from './components/Notes';
import PromptEditor from './components/PromptEditor';
import PromptsList from './components/PromptsList';
import Preferences from './components/Preferences';
import Profiles from './components/Profiles';
import UserManagement from './components/UserManagement';
import config from './config';

const getWindowState = (id, defaultState) => {
  const savedState = localStorage.getItem(`windowState-${id}`);
  return savedState ? JSON.parse(savedState) : defaultState;
};

const saveWindowState = (id, state) => {
  localStorage.setItem(`windowState-${id}`, JSON.stringify(state));
};

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [zIndices, setZIndices] = useState({
    chat1: 10,
    chat2: 10,
    chat3: 10,
    notes: 10,
    prompteditor: 10,
    promptslist: 10,
    preferences: 10,
    profiles: 10,
    usermanagement: 10
  });
  const [highestZIndex, setHighestZIndex] = useState(10);
  const [selectedPromptId, setSelectedPromptId] = useState(null);
  const [prompts, setPrompts] = useState([]);
  const [folded, setFolded] = useState({});
  const [originalHeights, setOriginalHeights] = useState({});
  const [selectedChatUuids, setSelectedChatUuids] = useState({
    chat1: null,
    chat2: null,
    chat3: null
  });
  const [draggedProfile, setDraggedProfile] = useState(null);

  useEffect(() => {
    checkAuth();
    fetchPrompts();
    document.title = "Karolinska Barnskyddsteamet";
  }, []);

  const checkAuth = async () => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/api/check_auth`, {
        method: 'GET',
        credentials: 'include',
      });
      const data = await response.json();
      if (data.authenticated) {
        setUser(data);
      }
    } catch (error) {
      console.error('Error checking authentication:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleLoginSuccess = (userData) => {
    setUser(userData);
  };

  const handleLogout = async () => {
    try {
      await fetch(`${config.apiBaseUrl}/api/logout`, {
        method: 'POST',
        credentials: 'include',
      });
      setUser(null);
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const fetchPrompts = () => {
    fetch(`${config.apiBaseUrl}/api/prompts`)
      .then(response => response.json())
      .then(data => {
        console.log('Fetched prompts:', data);
        setPrompts(data.map(prompt => ({
          ...prompt,
          status: prompt.status || 'enabled'
        })));
      })
      .catch(error => console.error('Error fetching prompts:', error));
  };

  const bringToFront = (id) => {
    const newHighestZIndex = highestZIndex + 1;
    setZIndices({ ...zIndices, [id]: newHighestZIndex });
    setHighestZIndex(newHighestZIndex);
  };

  const handleSelectPrompt = (id) => {
    setSelectedPromptId(id);
  };

  const handleSavePrompt = (updatedPrompt) => {
    if (!updatedPrompt || !updatedPrompt.id) {
      console.error('Updated prompt is missing id:', updatedPrompt);
      return;
    }
    console.log('Saving prompt:', updatedPrompt);
    setPrompts(prevPrompts => prevPrompts.map(prompt => (prompt.id === updatedPrompt.id ? updatedPrompt : prompt)));
  };

  const handleSaveAsNewPrompt = (newPrompt) => {
    console.log('Saving as new prompt:', newPrompt);
    setPrompts([...prompts, newPrompt]);
  };

  const toggleFold = (id) => {
    setFolded(prevFolded => {
      const isFolded = prevFolded[id];
      console.log(`Toggling fold for ${id}. Currently folded: ${isFolded}`);
      if (isFolded) {
        return { ...prevFolded, [id]: false };
      } else {
        return { ...prevFolded, [id]: true };
      }
    });
  };

  const handleUpdateSelectedChatUuid = (chatUuid, targetWindow) => {
    console.log('Updating selected chat UUID:', chatUuid);
    console.log('Target window:', targetWindow);

    setSelectedChatUuids(prevSelectedChatUuids => {
      const updatedChatUuids = {
        ...prevSelectedChatUuids,
        [targetWindow]: chatUuid
      };
      console.log('Updated chat UUIDs:', updatedChatUuids);
      return updatedChatUuids;
    });
  };

  const handleProfileDragStart = (profile) => {
    setDraggedProfile(profile);
  };

  const handleDrop = (e, containerId) => {
    e.preventDefault();
    if (draggedProfile && containerId.startsWith('chat')) {
      const chatComponent = document.querySelector(`#${containerId} .chat-component`);
      if (chatComponent) {
        const event = new CustomEvent('profileDropped', { 
          detail: { fullBackground: draggedProfile.full_background }
        });
        chatComponent.dispatchEvent(event);
      }
    }
    setDraggedProfile(null);
  };

  const containers = [
    { title: "Chat 1", defaultSize: { width: 320, height: 200, x: 0, y: 0 }, id: "chat1" },
    { title: "Chat 2", defaultSize: { width: 320, height: 200, x: 340, y: 0 }, id: "chat2" },
    { title: "Chat 3", defaultSize: { width: 320, height: 200, x: 680, y: 0 }, id: "chat3" },
    { title: "Notes", defaultSize: { width: 320, height: 200, x: 340, y: 220 }, id: "notes" },
    { title: "Prompt Editor", defaultSize: { width: 640, height: 400, x: 680, y: 0 }, id: "prompteditor" },
    { title: "Prompts List", defaultSize: { width: 640, height: 400, x: 680, y: 0 }, id: "promptslist" },
    { title: "Preferences", defaultSize: { width: 320, height: 200, x: 0, y: 440 }, id: "preferences" },
    { title: "Profiles", defaultSize: { width: 640, height: 400, x: 0, y: 220 }, id: "profiles" },
    { title: "User Management", defaultSize: { width: 640, height: 400, x: 0, y: 0 }, id: "usermanagement" }
  ];

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App">
      {!user ? (
        <>
          <CreateUserForm onUserCreated={setUser} />
          <LoginForm onLoginSuccess={handleLoginSuccess} />
        </>
      ) : (
        <>
          <button onClick={handleLogout} className="logout-button">Logout</button>
          {user.admin ? (
            containers.map((container, index) => {
              const savedState = getWindowState(container.id, container.defaultSize);
              const isFolded = folded[container.id];
              const currentHeight = originalHeights[container.id] || savedState.height || container.defaultSize.height;

              return (
                <Rnd
                  key={index}
                  default={{
                    x: savedState.x || container.defaultSize.x,
                    y: savedState.y || container.defaultSize.y,
                    width: savedState.width || container.defaultSize.width,
                    height: isFolded ? 30 : currentHeight,
                  }}
                  size={{ width: savedState.width || container.defaultSize.width, height: isFolded ? 30 : currentHeight }}
                  style={{ border: 'solid 1px #ddd', background: 'white', zIndex: zIndices[container.id], boxShadow: '14px 15px 37px -11px rgba(0,0,0,0.61)' }}
                  onMouseDown={() => bringToFront(container.id)}
                  dragHandleClassName={`title-bar-${container.id}`}
                  onDragStop={(e, d) => {
                    const newState = { ...savedState, x: d.x, y: d.y };
                    saveWindowState(container.id, newState);
                  }}
                  onResizeStop={(e, direction, ref, delta, position) => {
                    const newHeight = ref.style.height.replace('px', '');
                    const newState = {
                      ...savedState,
                      width: ref.style.width,
                      height: newHeight,
                      ...position
                    };
                    saveWindowState(container.id, newState);
                    setOriginalHeights(prevHeights => ({
                      ...prevHeights,
                      [container.id]: newHeight
                    }));
                  }}
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={(e) => handleDrop(e, container.id)}
                >
                  <div 
                    className={`title-bar title-bar-${container.id}`}
                    style={{ 
                      backgroundColor: '#eee', 
                      borderBottom: 'solid 1px #aaa', 
                      padding: '4px', 
                      cursor: 'move',
                      userSelect: 'none'
                    }}
                    onDoubleClick={() => {
                      const newHeight = savedState.height || container.defaultSize.height;
                      if (isFolded) {
                        setOriginalHeights(prev => ({ ...prev, [container.id]: newHeight }));
                      }
                      toggleFold(container.id);
                    }}
                  >
                    {container.title}
                  </div>
                  {!isFolded && (
                    <div className="content" style={{ padding: '10px', height: 'calc(100% - 30px)', overflow: 'auto' }}>
                      {container.id.startsWith("chat") && (
                        <Chat 
                          key={container.id}
                          onUpdateSelectedChatUuid={handleUpdateSelectedChatUuid}
                          selectedChatUuid={selectedChatUuids[container.id]}
                          currentChatWindow={container.id}
                        />
                      )}
                      {container.id === "notes" && <Notes />}
                      {container.id === "prompteditor" && (
                        <PromptEditor 
                          selectedPromptId={selectedPromptId} 
                          onSavePrompt={handleSavePrompt}
                          onSaveAsNewPrompt={handleSaveAsNewPrompt}
                        />
                      )}
                      {container.id === "promptslist" && (
                        <PromptsList 
                          onSelectPrompt={handleSelectPrompt}
                          prompts={prompts}
                        />
                      )}
                      {container.id === "preferences" && <Preferences />}
                      {container.id === "profiles" && <Profiles onDragStart={handleProfileDragStart} />}
                    {container.id === "usermanagement" && (
                      <UserManagement currentUser={user} />
                    )}
                    </div>
                  )}
                </Rnd>
              );
            })
          ) : (
            <NonAdminChat />
          )}
        </>
      )}
    </div>
  );
}

export default App;