import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import config from '../config';
import { renderMarkup } from 'react-render-markup';
import { Document, Packer, Paragraph, TextRun } from 'docx';
import { saveAs } from 'file-saver';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';

const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
const recognition = new SpeechRecognition();
recognition.continuous = false;
recognition.interimResults = false;

function Chat({ onUpdateSelectedChatUuid, selectedChatUuid, currentChatWindow }) {
  const initialMessages = [];
  const [messages, setMessages] = useState(initialMessages);
  const [newMessage, setNewMessage] = useState('');
  const [chatUuid, setChatUuid] = useState(uuidv4());
  const [models, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState('');
  const [savedChats, setSavedChats] = useState([]);
  const [loadedChatUuid, setLoadedChatUuid] = useState('');
  const [readReply, setReadReply] = useState(false);
  const [showHiddenPrompts, setShowHiddenPrompts] = useState(false);
  const [droppedProfile, setDroppedProfile] = useState(null);
  const [isFeedbackLoading, setIsFeedbackLoading] = useState(false);
  const [isProfileVisible, setIsProfileVisible] = useState(true);
  const [isPlayingAudio, setIsPlayingAudio] = useState(false);

  const [messageCount, setMessageCount] = useState(() => {
    const saved = localStorage.getItem('messageCount');
    return saved ? parseInt(saved, 10) : 0;
  });
  
  const toggleProfileVisibility = () => {
    setIsProfileVisible(!isProfileVisible);
  };
  
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);
  const chatRef = useRef(null);
  const audioRef = useRef(new Audio());

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    fetchModels();
    fetchSavedChats();
    scrollToBottom();
  }, [messages]);
  
  useEffect(() => {
    if (selectedChatUuid) {
      handleLoadChat(selectedChatUuid);
    }
  }, [selectedChatUuid]);

  useEffect(() => {
    const handleProfileDropped = (event) => {
      const { fullBackground } = event.detail;
      if (fullBackground) {
        const prefixedBackground = `NYTT SCENARIO:\n\n${fullBackground}`;
        handleSendMessage(prefixedBackground, 'special');
        setMessageCount(0);
        localStorage.setItem('messageCount', '0');
      }
    };

    const chatComponent = document.querySelector(`#${currentChatWindow} .chat-component`);
    if (chatComponent) {
      chatComponent.addEventListener('profileDropped', handleProfileDropped);
    }

    return () => {
      if (chatComponent) {
        chatComponent.removeEventListener('profileDropped', handleProfileDropped);
      }
    };
  }, [currentChatWindow]);

  const fetchModels = async () => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/api/preferences`);
      const data = await response.json();
      const modelsPreference = data.find(pref => pref.key === 'models');
      if (modelsPreference) {
        const modelsArray = modelsPreference.value.split(',');
        setModels(modelsArray);
        setSelectedModel(prevSelectedModel => prevSelectedModel || modelsArray[0]);
      }
    } catch (error) {
      console.error('Error fetching models:', error);
    }
  };

  const fetchSavedChats = async () => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/api/saved_chats`);
      const data = await response.json();
      setSavedChats(data);
    } catch (error) {
      console.error('Error fetching saved chats:', error);
    }
  };

  const checkForQuotesAndSpeak = async (text) => {
    console.log("Original text:", text);
    const asteriskRegex = /\*([^*]*)\*/g;
    const textWithoutAsterisks = text.replace(asteriskRegex, '');
    
    if (textWithoutAsterisks !== text) {
      console.log("Content within asterisks removed");
      console.log("Remaining text:", textWithoutAsterisks);
      await speakText(textWithoutAsterisks.trim());
    } else {
      console.log("No content within asterisks found");
      await speakText(text.trim());
    }
  };

  const speakText = async (text) => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/api/text-to-speech`, {
        method: 'POST',
        credentials: 'include',  // Add this line

        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text }),
      });

      if (!response.ok) {
        throw new Error('Failed to generate speech');
      }

      const blob = await response.blob();
      const audioUrl = URL.createObjectURL(blob);
      
      audioRef.current.src = audioUrl;
      audioRef.current.onended = () => setIsPlayingAudio(false);
      setIsPlayingAudio(true);
      await audioRef.current.play();
    } catch (error) {
      console.error('Error generating or playing speech:', error);
    }
  };

  const handleSendMessage = async (message, type = null) => {
    if (message.trim()) {
      const userMessage = { 
        text: message, 
        sender: "user", 
        model: selectedModel, 
        type: type
      };
      setMessages(prevMessages => [...prevMessages, userMessage]);
      setNewMessage('');
      
      const newMessageCount = messageCount + 1;
      setMessageCount(newMessageCount);
      localStorage.setItem('messageCount', newMessageCount.toString());
      
      const messageData = {
        chat_uuid: chatUuid,
        message: message,
        model: selectedModel,
        order: messages.length + 1,
        type: type
      };

      try {
        const response = await fetch(`${config.apiBaseUrl}/api/chat`, {
          method: 'POST',
          credentials: 'include',  // Add this line

          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(messageData)
        });

        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let botMessage = '';

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
          const chunk = decoder.decode(value, { stream: true });
          botMessage += chunk;
          setMessages(prevMessages => {
            const lastMessage = prevMessages[prevMessages.length - 1];
            if (lastMessage && lastMessage.sender === 'bot') {
              return [...prevMessages.slice(0, -1), { ...lastMessage, text: botMessage }];
            } else {
              return [...prevMessages, { text: botMessage, sender: 'bot', model: selectedModel }];
            }
          });
        }

        inputRef.current.focus();

        if (readReply) {
          await checkForQuotesAndSpeak(botMessage);
        }

      } catch (error) {
        console.error('Error in chat communication:', error);
      }
    }
  };

  const handleFeedbackClick = async () => {
    setIsFeedbackLoading(true);
    
    if (messages.length === 0) {
      console.error('No messages to provide feedback on');
      setIsFeedbackLoading(false);
      return;
    }
    
    const messageData = {
      chat_uuid: chatUuid,
      message: messages[messages.length - 1].text,
      model: selectedModel,
      order: messages.length
    };
    
    try {
      const helperResult = await callHelper(messageData);
      if (helperResult && helperResult.feedbacktochild && helperResult.feedbacktogrownup) {
        const formattedHelperMessage = `Förtroende: ${helperResult.feedbacktochild}\n\n${helperResult.feedbacktogrownup}`;
        
        setMessages(prevMessages => {
          const newMessages = [...prevMessages];
          const lastMessageIndex = newMessages.length - 1;
          newMessages[lastMessageIndex] = {
            ...newMessages[lastMessageIndex],
            feedback: formattedHelperMessage
          };
          return newMessages;
        });
        
        await fetch(`${config.apiBaseUrl}/api/update_feedback`, {
          method: 'POST',
          credentials: 'include',  // Add this line

          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ 
            chat_uuid: chatUuid, 
            order: messages.length, 
            feedback: formattedHelperMessage 
          })
        });
      }
    } catch (error) {
      console.error('Error getting feedback:', error);
    } finally {
      setIsFeedbackLoading(false);
    }
  };

  const callHelper = async (messageData) => {
    try {
      const helperResponse = await fetch(`${config.apiBaseUrl}/api/chat/helper`, {
        method: 'POST',
        credentials: 'include',  // Add this line

        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(messageData)
      });
      return await helperResponse.json();
    } catch (error) {
      console.error('Error calling helper:', error);
      return null;
    }
  };

  const getMessageStyle = (type, sender) => {
    if (type === 'special') {
      return 'bg-red-100 border-red-300 border-2';
    }
    if (type === 'helper') {
      return 'bg-green-100 border-green-300 border-2';
    }
    return sender === "user" ? 'bg-blue-200' : 'bg-gray-200';
  };

  const shouldShowMessage = (message) => {
    return showHiddenPrompts || message.type !== 'special';
  };

  const handleClearChat = () => {
    setMessages([]);
    setChatUuid(uuidv4());
    setDroppedProfile(null);
    setMessageCount(0);
    localStorage.setItem('messageCount', '0');
  };

  const handleSaveChat = async () => {
    const description = prompt('Enter a description for this chat:');
    if (description) {
      try {
        const response = await fetch(`${config.apiBaseUrl}/api/saved_chats`, {
          method: 'POST',
          credentials: 'include',  // Add this line

          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ chat_uuid: chatUuid, description })
        });
        const data = await response.json();
        alert(data.message);
        fetchSavedChats();
      } catch (error) {
        console.error('Error saving chat:', error);
      }
    }
  };

  const handleLoadChat = async (chatUuid) => {
    console.log('Loading chat:', chatUuid);
    
    if (chatUuid) {
      try {
        const response = await fetch(`${config.apiBaseUrl}/api/load_chat?chat_uuid=${chatUuid}`);
        const data = await response.json();
        const loadedMessages = data.map(msg => ({
          text: msg.content,
          sender: msg.role === 'user' ? 'user' : 'bot',
          model: msg.model,
          note: msg.note,
          type: msg.type
        }));
        setMessages(loadedMessages);
        setChatUuid(chatUuid);
        setLoadedChatUuid(chatUuid);
      } catch (error) {
        console.error('Error loading chat:', error);
      }
    }
  };
  
  const handleSelectLoadChat = (event) => {
    const selectedChatUuid = event.target.value;
    handleLoadChat(selectedChatUuid);
  };

  const handleNoteClick = (index) => {
    const currentNote = messages[index].note || '';
    const note = prompt('Enter a note for this message:', currentNote);
    if (note !== null) {
      setMessages(prevMessages => {
        const newMessages = [...prevMessages];
        newMessages[index] = { ...newMessages[index], note };
        fetch(`${config.apiBaseUrl}/api/update_note`, {
          method: 'POST',
          credentials: 'include',  // Add this line

          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ chat_uuid: chatUuid, order: index + 1, note })
        });
        return newMessages;
      });
    }
  };

  const handleExportChat = async () => {
    try {
      const doc = new Document({
        sections: [
          {
            properties: {},
            children: messages.map((message) => {
              if (!message || !message.text) {
                console.error('Invalid message:', message);
                return new Paragraph({});
              }
              if (message.type === 'helper') {
                const [feedback, grownup] = message.text.split('\n\n');
                return new Paragraph({
                  children: [
                    new TextRun({ text: 'Helper:\n', bold: true }),
                    new TextRun({ text: feedback + '\n' }),
                    new TextRun({ text: grownup })
                  ]
                });
              }
              return new Paragraph({
                children: [
                  new TextRun({
                    text: message.sender === 'user' ? 'User: ' : 'Bot: ',
                    bold: true
                  }),
                  new TextRun({
                    text: message.text.replace(/<br>/g, '\n'),
                  }),
                  message.model ? new TextRun({ text: ` (Model: ${message.model})`, italics: true }) : null,
                  message.type ? new TextRun({ text: ` [Type: ${message.type}]`, color: "FF0000" }) : null,
                  message.note ? new TextRun({ text: `\nNote: ${message.note.replace(/<br>/g, '\n')}`, underline: true }) : null
                ].filter(Boolean)
              });
            })
          }
        ]
      });

      const blob = await Packer.toBlob(doc);
      saveAs(blob, `chat-${chatUuid}.docx`);
    } catch (error) {
      console.error('Error exporting chat:', error);
    }
  };

  const escapeHtml = (text) => {
    return text.replace(/&/g, "&amp;")
               .replace(/</g, "&lt;")
               .replace(/>/g, "&gt;")
               .replace(/"/g, "&quot;")
               .replace(/'/g, "&#039;");
  };

  const handleSpeechStart = () => {
    recognition.start();
  };

  const handleSpeechEnd = () => {
    recognition.stop();
  };
  
  recognition.onresult = (event) => {
    const transcript = event.results[0][0].transcript;
    setNewMessage(transcript);
  };
  
  recognition.onerror = (event) => {
    console.error('Speech recognition error:', event);
  };
  
  const handleCopyChat = async (index) => {
    const messagesToCopy = messages.slice(0, index + 1);
    const newChatUuid = uuidv4();
    
    console.log('Copying chat messages:', messagesToCopy);
    console.log('New chat UUID:', newChatUuid);
    
    try {
      const response = await fetch(`${config.apiBaseUrl}/api/copy_chat`, {
        method: 'POST',
        credentials: 'include',  // Add this line

        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ messages: messagesToCopy, new_chat_uuid: newChatUuid })
      });
      
      if (response.ok) {
        const targetChatWindow = prompt('Enter the target chat window (1, 2, or 3):');
        console.log('Target chat window:', targetChatWindow);
        
        if (targetChatWindow === '1' || targetChatWindow === '2' || targetChatWindow === '3') {
          onUpdateSelectedChatUuid(newChatUuid, `chat${targetChatWindow}`);
        }
      } else {
        console.error('Error copying chat:', response.statusText);
      }
    } catch (error) {
      console.error('Error copying chat:', error);
    }
  };
  
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    const data = e.dataTransfer.getData('application/json');
    if (data) {
      try {
        const droppedItem = JSON.parse(data);
        if (droppedItem.type === 'prompt' && droppedItem.prompt) {
          const newMessage = {
            text: droppedItem.prompt,
            sender: "user",
            model: selectedModel,
            type: 'special'
          };
          setMessages(prevMessages => [...prevMessages, newMessage]);
          
          handleSendMessage(droppedItem.prompt, 'special');
          setMessageCount(0);
          localStorage.setItem('messageCount', '0');
        } else if (droppedItem.full_background) {
          const prefixedBackground = `NYTT SCENARIO:\n\n${droppedItem.full_background}`;
          const newMessage = {
            text: prefixedBackground,
            sender: "user",
            model: selectedModel,
            type: 'special'
          };
          setMessages(prevMessages => [...prevMessages, newMessage]);
          
          handleSendMessage(prefixedBackground, 'special');
          setDroppedProfile(droppedItem);
          setMessageCount(0);
          localStorage.setItem('messageCount', '0');
        }
      } catch (error) {
        console.error('Error parsing dropped data:', error);
      }
    }
  };
  
  const handleRemoveProfile = () => {
    setDroppedProfile(null);
  };
  
  return (
    <div 
    className="chat-container flex flex-col h-full relative chat-component" 
    ref={chatRef}
    onDragOver={handleDragOver}
    onDrop={handleDrop}
    >
    {droppedProfile && (
      <div className="absolute top-[68px] left-[30px] bg-white p-4 rounded shadow-lg transform rotate-[-3deg] z-10 max-w-xs">
      <div className="absolute -top-4 -left-2 w-6 h-6 bg-gray-300 rounded-full flex items-center justify-center transform rotate-45">
      <button 
      onClick={toggleProfileVisibility}
      className="w-full h-full flex items-center justify-center focus:outline-none"
      >
      <FontAwesomeIcon 
      icon={faPaperclip} 
      className="text-gray-600 transform -rotate-45"
      />
      </button>
      </div>
      {isProfileVisible && (
        <>
        <img 
        src={`${config.apiBaseUrl}/api/profiles/${droppedProfile.id}/image`} 
        alt={droppedProfile.name}
        className="w-full h-32 object-cover mb-2 rounded"
        />
        <h3 className="font-bold text-lg mb-1">{droppedProfile.name}</h3>
        <p className="text-sm mb-2">{droppedProfile.description}</p>
        <button 
        onClick={handleRemoveProfile}
        className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center"
        >
        ×
        </button>
        </>
      )}
      </div>
    )}
    <div className="chat-controls flex items-center justify-between p-2">
    <div className="flex items-center">
    <button onClick={handleClearChat} className="clear-button bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded">
    <i className="fas fa-trash-alt"></i>
    </button>
    <label className="flex items-center ml-2">
    <input
    type="checkbox"
    checked={showHiddenPrompts}
    onChange={(e) => setShowHiddenPrompts(e.target.checked)}
    className="mr-2"
    />
    <span className="text-sm">Show hidden prompts</span>
    </label>
    </div>
    <div className="flex items-center">
    <select onChange={handleSelectLoadChat} value={loadedChatUuid} className="load-chat-select ml-2 p-2 border rounded">
    <option value="">Load a saved chat</option>
    {savedChats.map(chat => (
      <option key={chat.chat_uuid} value={chat.chat_uuid}>
      {chat.description} ({new Date(chat.timestamp).toLocaleString()})
      </option>
    ))}
    </select>
    <button onClick={handleSaveChat} className="save-button bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded ml-2">
    <i className="fas fa-save"></i>
    </button>
    <button onClick={handleExportChat} className="export-button bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded ml-2">
    <i className="fas fa-file-export"></i>
    </button>
    </div>
    </div>
    <div className="messages-list flex-1 overflow-auto p-4">
    {messages.map((message, index) => (
      shouldShowMessage(message) && (
        <div key={index} className="message-container">
        <div className={`message flex items-center my-2 p-2 rounded w-4/5 ${message.sender === "user" ? 'justify-end ml-auto' : 'justify-start mr-auto'} ${getMessageStyle(message.type, message.sender)}`}>
        {message.sender === "bot" && <i className="fas fa-robot mr-2"></i>}
        {message.sender === "helper" && <i className="fas fa-magic mr-2"></i>}
        <span className={`message-text flex-1 ${message.sender === "user" ? 'text-right' : 'text-left'}`}>
        {renderMarkup(escapeHtml(message.text))}
        <br/>
        {message.model && <span className="model-indicator text-xs text-gray-500">({message.model})</span>}
        {message.sender !== 'helper' && (
          <>
          <i className="fas fa-sticky-note ml-2 cursor-pointer" style={{color: 'black'}} onClick={() => handleNoteClick(index)}></i>
          <i className="fas fa-code-branch ml-2 cursor-pointer" onClick={() => handleCopyChat(index)}></i>
          </>
        )}
        </span>
        {message.sender === "user" && <i className="fas fa-user ml-2"></i>}
        </div>
        {message.note && (
          <div className={`note flex items-center my-2 p-2 rounded w-4/5 ${message.sender === "user" ? 'justify-end bg-yellow-200 ml-auto' : 'justify-start bg-yellow-200 mr-auto'}`}>
          {renderMarkup(escapeHtml(message.note))}
          </div>
        )}
        {message.feedback && (
          <div className="feedback flex items-start my-2 p-2 rounded w-4/5 bg-green-100 mr-auto">
          <div className="text-left">
          <div className="font-bold">Förtroende:</div>
          <div className="mb-2">{message.feedback.split('\n\n')[0].replace('Förtroende: ', '')}</div>
          <div className="font-bold">Feedback:</div>
          <div>{message.feedback.split('\n\n')[1]}</div>
          </div>
          </div>
        )}
        </div>
      )
    ))}
    <div ref={messagesEndRef} />
    </div>
    <form onSubmit={(e) => { e.preventDefault(); handleSendMessage(newMessage); }} className="chat-input flex p-2">
    <input
    type="text"
    value={newMessage}
    onChange={(e) => setNewMessage(e.target.value)}
    placeholder="Type a message..."
    className="flex-1 border rounded p-2"
    ref={inputRef}
    />
    <select
    value={selectedModel}
    onChange={(e) => setSelectedModel(e.target.value)}
    className="model-select ml-2 p-2 border rounded"
    >
    {models.map(model => (
      <option key={model} value={model}>{model}</option>
    ))}
    </select>
    <button type="submit" className="send-button bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-2">
    Send
    </button>
    <button 
    type="button"
    onClick={handleFeedbackClick}
    disabled={isFeedbackLoading || messages.length === 0}
    className={`feedback-button ${
      isFeedbackLoading || messages.length === 0
      ? 'bg-gray-500 cursor-not-allowed' 
      : 'bg-green-500 hover:bg-green-700'
      } text-white font-bold py-2 px-4 rounded ml-2 flex items-center justify-center`}
    >
    {isFeedbackLoading ? (
      <>
      <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
      Loading...
      </>
    ) : (
      'Feedback'
    )}
    </button>
    <button 
    type="button" 
    className="talk-button bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-2"
    onMouseDown={handleSpeechStart} 
    onMouseUp={handleSpeechEnd}
    onTouchStart={handleSpeechStart}
    onTouchEnd={handleSpeechEnd}
    >
    <i className="fas fa-microphone"></i>
    </button>
    <label className="read-reply-checkbox ml-2 flex items-center">
    <input
    type="checkbox"
    checked={readReply}
    onChange={(e) => setReadReply(e.target.checked)}
    className="mr-2"
    />
    Read
    </label>
    </form>
    {isPlayingAudio && (
      <div className="fixed bottom-4 right-4 bg-blue-100 border-blue-500 border-2 p-4 rounded shadow-lg">
      Playing audio...
      </div>
    )}
    </div>
  );
}

export default Chat;