import React, { useState, useEffect } from 'react';
import config from '../config';

const PromptEditor = ({ selectedPromptId, onSavePrompt, onSaveAsNewPrompt }) => {
    const [prompt, setPrompt] = useState({
        name: '',
        description: '',
        prompt: '',
        status: 'enabled',
        comments: ''
    });
    const [isNew, setIsNew] = useState(true);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [saveSuccess, setSaveSuccess] = useState(false);

    useEffect(() => {
        if (selectedPromptId) {
            setIsNew(false);
            fetchPrompt(selectedPromptId);
        } else {
            setIsNew(true);
            setPrompt({
                name: '',
                description: '',
                prompt: '',
                status: 'enabled',
                comments: ''
            });
        }
    }, [selectedPromptId]);

    const fetchPrompt = async (id) => {
        setLoading(true);
        try {
            const response = await fetch(`${config.apiBaseUrl}/api/prompts/${id}`, {
                credentials: 'include'
            });
            if (!response.ok) throw new Error('Failed to fetch prompt');
            const data = await response.json();
            setPrompt(data);
            setError('');
        } catch (error) {
            setError('Error fetching prompt: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPrompt(prevPrompt => ({
            ...prevPrompt,
            [name]: value
        }));
    };

    const handleSave = async () => {
        setLoading(true);
        setSaveSuccess(false);
        try {
            const url = isNew ? `${config.apiBaseUrl}/api/prompts` : `${config.apiBaseUrl}/api/prompts/${prompt.id}`;
            const method = isNew ? 'POST' : 'PUT';

            const response = await fetch(url, {
                method: method,
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(prompt)
            });

            if (!response.ok) throw new Error('Failed to save prompt');
            const data = await response.json();
            
            if (isNew) {
                onSaveAsNewPrompt(data.prompt);
                setIsNew(false);
            } else {
                onSavePrompt(data.prompt);
            }
            
            setError('');
            setSaveSuccess(true);
            setTimeout(() => setSaveSuccess(false), 3000); // Clear success message after 3 seconds
        } catch (error) {
            setError('Error saving prompt: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleSaveAsNew = async () => {
        setLoading(true);
        setSaveSuccess(false);
        try {
            const newPrompt = { ...prompt };
            delete newPrompt.id; // Remove the id to ensure a new prompt is created

            const response = await fetch(`${config.apiBaseUrl}/api/prompts`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(newPrompt)
            });

            if (!response.ok) throw new Error('Failed to save prompt as new');
            const data = await response.json();
            
            onSaveAsNewPrompt(data.prompt);
            setIsNew(false);
            setPrompt(data.prompt);
            setError('');
            setSaveSuccess(true);
            setTimeout(() => setSaveSuccess(false), 3000); // Clear success message after 3 seconds
        } catch (error) {
            setError('Error saving prompt as new: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <div className="prompt-editor">Loading...</div>;
    }

    return (
        <div className="prompt-editor">
            <h2>{isNew ? 'Create New Prompt' : 'Edit Prompt'}</h2>
            {error && <p className="error">{error}</p>}
            {saveSuccess && <p className="success">Prompt saved successfully!</p>}
            <form onSubmit={(e) => e.preventDefault()}>
                <div className="form-group">
                    <label htmlFor="name">Name:</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={prompt.name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="description">Description:</label>
                    <textarea
                        id="description"
                        name="description"
                        value={prompt.description}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="prompt">Prompt:</label>
                    <textarea
                        id="prompt"
                        name="prompt"
                        value={prompt.prompt}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="status">Status:</label>
                    <select
                        id="status"
                        name="status"
                        value={prompt.status}
                        onChange={handleChange}
                    >
                        <option value="enabled">Enabled</option>
                        <option value="disabled">Disabled</option>
                        <option value="hidden">Hidden</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="comments">Comments:</label>
                    <textarea
                        id="comments"
                        name="comments"
                        value={prompt.comments}
                        onChange={handleChange}
                    />
                </div>
                <div className="button-group">
                    <button type="button" onClick={handleSave} disabled={loading}>
                        {isNew ? 'Create Prompt' : 'Update Prompt'}
                    </button>
                    {!isNew && (
                        <button type="button" onClick={handleSaveAsNew} disabled={loading}>
                            Save As New
                        </button>
                    )}
                </div>
            </form>
        </div>
    );
};

export default PromptEditor;